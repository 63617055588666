<template>
    <div class="controlOverInvoices">
        <van-nav-bar
            title="发票抬头管理"
            left-arrow
            @click-left="onClickLeft"
            fixed
            :z-index="100"
        />
            <div class="section">
              <van-list
                
                v-model="loading"
                :finished="finished"
                finished-text="--没有更多了--"
                @load="onLoad"
                >
                <div class="section-list">
                    <div class="section-title" v-show="AAA" >普通发票抬头-个人</div>
                    <van-cell is-link v-for="item in person" :key="item.id" :title="item.invoice" :to="{name:'deleteInvoiceManagement',query:{item:JSON.stringify(item)}}">
                        <van-radio-group v-if="item.isDefult == 1" v-model="radio" direction="horizontal">
                        <van-radio name="1">默认</van-radio>
                        </van-radio-group>
                    </van-cell>
                </div>

                <div class="section-list">
                    <div class="section-title" v-show="BBB">普通发票抬头-单位</div>
                    <van-cell is-link v-for="item in company" :key="item.item" :title="item.invoice" :to="{name:'deleteInvoiceManagement',query:{item:JSON.stringify(item)}}">
                            <van-radio-group v-if="item.isDefult == 1" v-model="radio" direction="horizontal">
                            <van-radio name="1">默认</van-radio>
                            </van-radio-group>
                        
                    </van-cell>
                </div>
            </van-list>
            </div>
        <!-- 尾部 -->
        <div class="submitBtn">
            <van-button class="submitBtn-btn" round block @click="addInvoiceHeaderBtn">
                添加发票抬头
            </van-button>
        </div>
    </div>
</template>

<script>

export default {

    data(){
        return{
            company: [],
            person:[],
            loading: false,
            finished: false,
            // total:0,
            // page:1,
            // limit:"",
            radio:"1",
            AAA:false,
            BBB:false,
        }
    },
    created () { 
       this.getInvoiceUserListGet();
      
    },

    
    methods:{
        // 返回
        onClickLeft() {
            this.$router.push("/my")
        },
        // 跳转添加发票抬头页面
        addInvoiceHeaderBtn(){
            this.$router.push("/addInvoiceHeader")
        },
        // 获取发票列表
        getInvoiceUserListGet(){
            console.log("获取发票列表");
            this.$api.my.getInvoiceUserList({
                accessToken:localStorage.getItem("token"),
                // page:1,
                // limit:this.limit,
            }).then((res) =>{
                if (res.data.person.length === 0) {
                    this.AAA = false;
                }else{
                    this.AAA = true;
                }

                if (res.data.company.length === 0) {
                    this.BBB = false;
                }else{
                    this.BBB = true;
                }
                
                if (res.errno === 200) {
                    // this.total = res.data.total;
                    this.company = res.data.company;
                    this.person = res.data.person;
                    console.log(res);
                }
                
                
            })
        },
        onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    //   setTimeout(() => {
    //     for (let i = 0; i < this.limit; i++) {
    //       this.company.push(this.company.length + 1);
    //       this.person.push(this.person.length + 1);
    //     }

        // 加载状态结束
        this.loading = false;
        this.finished = true;

    //     // 数据全部加载完成
        // if (this.company.length && this.person.length >= this.total) {
        // }
    //   }, 1000);
    },
  
    }
}
</script>

<style lang="less" scoped>
@import "../../../assets/styles/element.less";
html,body{
    height: 100% !important;
    background-color: #f7f7f7 !important;
}
.controlOverInvoices{
    width: 100vw;
    height: 84vh;
    overflow-y: scroll;
    position: relative;
    .section {
        position: relative;
        top: 46px;
        width: 100%;
        height: calc(100vh - 46px);
        // flex-direction: column;
        // align-items: center;
        word-wrap: break-word;
        margin-top: .22rem /* 11/50 */;
        .section-list{
            margin: 0 .3rem;
            margin-bottom: .2rem /* 10/50 */;
            border-radius: .1rem;
            box-shadow: 0px 0px .15rem 0px rgba(0, 0, 0, 0.05); 

            overflow: hidden;
            .section-title{
            font-size: .28rem /* 14/50 */;
            padding-left: 16px;
            background: #fff;
            line-height: .8rem /* 40/50 */;
            border-bottom: .02rem /* 1/50 */ #ccc solid;
        }
        }
       
    }
    .submitBtn{
        position:fixed;
        bottom: .1rem /* 5/50 */;
        left: 0;
        width: 91%;
        margin: 0 .32rem /* 16/50 */ .32rem /* 16/50 */ .32rem /* 16/50 */;
        
        .submitBtn-btn{
            // background: #C83F3F;
            color: #fff;
            // background: linear-gradient(0deg, #EE3A3A, #F46161);
            background: @ordinary_btn_color;
            border-radius: .1rem;
            font-size: .36rem;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: .36rem;
        }
    }
}
::v-deep .van-cell{
          left: 0;
          right: 0;
      .van-radio-group--horizontal{  
        .van-radio--horizontal{
          margin-right: 0;
          margin-left: .24rem /* 12/50 */;
        }
      }
      .van-field__label{
        color: #333;
      }
      .van-radio__icon--checked .van-icon{
        background-color: @radio_checked_color;
        border-color: @radio_checked_color;
        justify-content: flex-end
      }
    }
    .van-button--danger{
        background-color: @ordinary_btn_color;
        border: 1px solid @ordinary_btn_border_color;
      }
    .van-radio-group--horizontal {
        display: flex;
        flex-wrap: wrap;   
        justify-content: flex-end 
    }
</style>